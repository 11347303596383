import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiErrorResponse,
  TestIntegrationCredentialsResponse,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'

const fetchTestIntegrationCredentials = async (
  integrationId: number
): Promise<TestIntegrationCredentialsResponse | undefined> => {
  if (!integrationId) {
    return undefined
  }
  const apiResponse = await apiAuthClient.get(
    endpoints.testIntegrationCredentialsById({
      integrationId: `${integrationId}`,
    })
  )
  return apiResponse.data
}

export const useTestIntegrationCredentialsById = (integrationId?: number) =>
  useQuery<TestIntegrationCredentialsResponse | undefined, ApiErrorResponse>(
    ['testIntegration', integrationId],
    () => fetchTestIntegrationCredentials(integrationId!),
    {
      enabled: Boolean(integrationId),
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  )
