import {
  ApiBridgeResponse,
  ApiMappedField,
  ApiSaveBridgeBody,
} from 'BackendApi'
import { BridgeFormValues } from 'Nbee'
import { isFieldMapped } from '@features/nbee/FieldsMappingForm/utils'
const emptyBridge: BridgeFormValues = {
  name: '',
  source: {},
  destination: {},
  settings: {
    emailReceipt: {
      active: false,
      recipients: [],
      subject: '',
    },
  },
  sourceFilters: {
    conditions: [],
  },
}

export const transformBridgeApiToBridgeFormValues = (
  apiReponse: ApiBridgeResponse,
  includeFieldMapping?: boolean
): BridgeFormValues => {
  if (!apiReponse || !apiReponse.data || !apiReponse.data.bridge) {
    return emptyBridge
  }

  const bridgeConfiguration: BridgeFormValues = {
    name: apiReponse.data.bridge.name,
    source: {
      appId: apiReponse.data.bridge.source?.appId,
      integrationId: apiReponse.data.bridge.source?.integrationId,
      settings: apiReponse.data.bridge.source?.settings || [],
    },
    destination: {
      appId: apiReponse.data.bridge.destination?.appId,
      integrationId: apiReponse.data.bridge.destination?.integrationId,
      settings: apiReponse.data.bridge.destination?.settings || [],
    },
    ui: {
      bridgeId: apiReponse.data.bridge.id,
      isBridgeEnabled: !apiReponse.data.bridge.ui?.isEditable,
      testEnabled: apiReponse.data.bridge.ui?.testEnabled,
      emailReceipt: apiReponse.data.bridge.ui?.emailReceipt,
    },
    settings: {
      emailReceipt: {
        active: Boolean(apiReponse.data.bridge.settings?.emailReceipt.active),
        recipients:
          apiReponse.data.bridge.settings?.emailReceipt.recipients || [],
        subject: apiReponse.data.bridge.settings?.emailReceipt.subject || '',
      },
    },
    sourceFilters: {
      conditions: apiReponse.data.bridge.sourceFilters?.conditions || [],
    },
    manualStatus: apiReponse.data.bridge.manualStatus, // if value is 1, then this is the first bridge
  }

  const bridgeFieldMappings: {
    fieldsMapping: BridgeFormValues['fieldsMapping']
  } = {
    fieldsMapping: (apiReponse.data.bridge.fieldsMapping || []).map(
      (apiField) => ({
        destinationFieldId: apiField.destinationFieldId,
        destinationFieldType: apiField.destinationFieldType,
        sourceFieldId: apiField.sourceFieldId,
        mappingType: apiField.mappingType,
        destinationText: apiField.destinationText,
        formula: apiField.formula,
      })
    ),
  }

  return includeFieldMapping
    ? {
        ...bridgeConfiguration,
        ...bridgeFieldMappings,
      }
    : {
        ...bridgeConfiguration,
      }
}

export const transformBridgeFormValuesToApiSaveBridgeBody = (
  formValues: BridgeFormValues,
  options?: {
    step?: 1 | 2
  }
): ApiSaveBridgeBody => {
  const fieldsMapping: ApiMappedField[] = (formValues.fieldsMapping || [])
    .filter(isFieldMapped)
    .map((field) => ({
      destinationFieldId: field.destinationFieldId,
      destinationFieldType: field.destinationFieldType,
      mappingType: field.mappingType,
      destinationText: field.destinationText,
      sourceFieldId: field.sourceFieldId,
      formula: field.formula,
    }))

  return {
    name: formValues.name,
    source: formValues.source,
    destination: formValues.destination,
    fieldsMapping: fieldsMapping,
    settings: {
      emailReceipt: {
        // if canShow is undefined or true, we want to only look at the active status, otherwise set the active status to false
        active:
          Boolean(formValues.settings?.emailReceipt?.active) &&
          (formValues.settings?.emailReceipt?.canShow ?? true),
        recipients: formValues.settings?.emailReceipt?.recipients,
        subject: formValues.settings?.emailReceipt?.subject,
      },
    },
    sourceFilters: formValues?.sourceFilters,
    ui: options?.step
      ? {
          step: options?.step,
        }
      : undefined,
  }
}
