import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'

type PostVerifyLinkParams = {
  code: string
}

const postVerifyLink = async ({ code }: PostVerifyLinkParams) => {
  const apiResponse = await apiAuthClient.post(endpoints.postVerifyLink, {
    code: code,
  })
  return apiResponse.data
}

export const useVerifyLink = () =>
  useMutation<unknown, ApiErrorResponse, PostVerifyLinkParams>(postVerifyLink)
