import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiBridgeResponse, ApiErrorResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { transformBridgeApiToBridgeFormValues } from '@app/api/utils/bridge'
import { BridgeFormValues } from 'Nbee'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const fetchBridgeById = async (
  bridgeId: string
): Promise<ApiBridgeResponse> => {
  const apiResponse = await apiAuthClient.get(
    endpoints.getBridgeById({ bridgeId: bridgeId }),
    {
      headers: {
        ...getRandomExampleHeader([
          'with-mapped-field',
          // 'with-filters',
          // 'with-empty-filters',
          'with-mapped-field',
          // 'no-mapped-fields',
          // 'with-mapped-field',
          // 'disabled',
          // 'with-formula',
        ]),
      },
    }
  )
  return apiResponse.data
}

export const useGetBridgeById = (
  bridgeId?: string,
  includeFieldsMapping?: boolean
) =>
  useQuery<ApiBridgeResponse, ApiErrorResponse, BridgeFormValues>(
    ['bridge', bridgeId],
    () => fetchBridgeById(bridgeId!),
    {
      select: (data) =>
        transformBridgeApiToBridgeFormValues(data, includeFieldsMapping),
      enabled: !!bridgeId,
      staleTime: 0,
    }
  )
