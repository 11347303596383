import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'

type PostVerifyCodeParams = {
  email: string
  code: string
}

const postVerifyCode = async ({ email, code }: PostVerifyCodeParams) => {
  const apiResponse = await apiAuthClient.post(endpoints.postVerifyCode, {
    email: email,
    code: code,
  })
  return apiResponse.data
}

export const useVerifyCode = () =>
  useMutation<unknown, ApiErrorResponse, PostVerifyCodeParams>(postVerifyCode)
