import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiAppAuthSchemaResponse,
  ApiAppSettingsRequestItem,
  ApiErrorResponse,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { getRandomExampleHeader } from '@app/api/utils/mock'

interface PostRetrieveAppCredentialsParams {
  settings: ApiAppSettingsRequestItem[]
  refresh?: string[]
  appId: number
}

const postRetrieveAppCredentials = async ({
  settings,
  refresh,
  appId,
}: PostRetrieveAppCredentialsParams): Promise<ApiAppAuthSchemaResponse> => {
  const apiResponse: AxiosResponse<ApiAppAuthSchemaResponse> =
    await apiAuthClient.post(
      endpoints.getAppAuthSchemaByAppId({ appId: appId }),
      {
        settings: settings,
        refresh: refresh,
      },
      {
        headers: {
          ...getRandomExampleHeader([
            // 'example-webhook',
            // 'example-2',
            'example-1',
            // 'example-with-select',
            // 'example-with-oauth',
          ]),
        },
      }
    )

  return apiResponse.data
}

export const useRetrieveAppCredentials = () =>
  useMutation<
    ApiAppAuthSchemaResponse,
    ApiErrorResponse,
    PostRetrieveAppCredentialsParams,
    unknown
  >(postRetrieveAppCredentials)
