import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiGetAppsResponse } from 'BackendApi'
import { adaptApiAppReponse } from '@app/api/utils/apps'
import { apiAuthClient } from '@app/services/apiAuthClient'

type GetMostPopularOptions = {
  type?: 'source' | 'destination' | 'both'
  limit?: number
}

const fetchMostPopularApps = async ({
  type,
  limit,
}: GetMostPopularOptions): Promise<ApiGetAppsResponse> => {
  const apiResponse = await apiAuthClient.get(endpoints.getMostPopularApps, {
    params: {
      type: type,
      limit: limit,
    },
  })
  return apiResponse.data
}

export const useFetchMostPopularApps = ({
  type,
  limit,
}: GetMostPopularOptions) =>
  useQuery(
    ['mostPopularApps', type],
    () => fetchMostPopularApps({ type: type, limit: limit }),
    {
      staleTime: Infinity,
      select: adaptApiAppReponse,
    }
  )
