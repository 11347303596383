import { ApiErrorResponse } from 'BackendApi'
import t from 'public/locales/en-US.json'

type SimpleErrorMessage = {
  status?: number
  code: number | string
  message: string
}

// this is related to laravel api (v3)
export const parseApiError = (
  apiError: ApiErrorResponse | null
): SimpleErrorMessage => {
  const errorCode = apiError?.response?.data.error?.code || 400
  const errorMessage = apiError?.response?.data.error?.message || null
  const status = apiError?.response?.status

  // if an error code is found in i18n we use the translated key
  // otherwise we return the errorMessage
  const localizedErrorMessage = (t.api.error as any)[errorCode]
  const defaultMessage = t.api.error.genericNoMessageReceived

  if (errorMessage === null) {
    return {
      status: status,
      code: errorCode,
      message: localizedErrorMessage || defaultMessage,
    }
  }

  // check if message is a composite object
  if (typeof errorMessage === 'object') {
    return {
      status: status,
      code: errorCode,
      message: Object.keys(errorMessage)
        .map((k) => errorMessage[k])
        .join(' '),
    }
  }

  // most of the time is a simple string
  if (typeof errorMessage === 'string') {
    return {
      status: status,
      code: errorCode,
      message: localizedErrorMessage || errorMessage || defaultMessage,
    }
  }

  // no case handle
  return {
    code: errorCode,
    message: defaultMessage,
  }
}
