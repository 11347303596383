import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiGetIntegrationUsageResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'

const fetchIntegrationUsage = async (
  integrationId: string | number,
  excludeBridgeId?: number
): Promise<ApiGetIntegrationUsageResponse> => {
  const apiResponse = await apiAuthClient.get(
    endpoints.getIntegrationUsage({ integrationId: integrationId }),
    {
      params: {
        excludeBridgeId: excludeBridgeId,
      },
    }
  )
  return apiResponse.data
}

export const useGetIntegrationUsage = (
  integrationId?: string | number,
  excludeBridgeId?: number
) =>
  useQuery<ApiGetIntegrationUsageResponse, ApiErrorResponse>(
    ['integration', integrationId],
    () => fetchIntegrationUsage(integrationId!, excludeBridgeId),
    {
      enabled: !!integrationId,
    }
  )
