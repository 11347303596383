import { ApiApp, ApiGetAppsResponse } from 'BackendApi'
import { App } from 'Nbee'

const convertApiAppModelToNbeApp = (app: ApiApp): App => ({
  id: app.id,
  name: app.name,
  logoUri: app.logoUri,
  logoUriSmall: app.xsLogoUri,
  video: app.video,
  authType: app.authType,
})

export const adaptApiAppReponse = (apiReponse: ApiGetAppsResponse): App[] => {
  const apiData = apiReponse?.data?.apps
  if (!apiData) {
    return []
  }

  return apiData.map(convertApiAppModelToNbeApp)
}
