import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import { axiosInterceptor } from '@app/services/axiosInterceptor'

// Type defintions
interface ApiPostStoreFastAppSurveyData {
  email: string
  phone?: string
  industry?: string
  privacyConsent?: boolean
}

export const useStoreSurveyData = () =>
  useMutation((apiInput: ApiPostStoreFastAppSurveyData) => {
    return axiosInterceptor().then((axios) =>
      axios.post(endpoints.storeAppSurvey, apiInput)
    )
  })
