import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiAppSettingsRequestBody,
  ApiAppSettingsResponse,
  ApiErrorResponse,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { getRandomExampleHeader } from '@app/api/utils/mock'

interface PostChooseIntegrationSetting {
  integrationSettingsData: ApiAppSettingsRequestBody
}

const postChooseIntegrationSettings = async ({
  integrationSettingsData,
}: PostChooseIntegrationSetting): Promise<ApiAppSettingsResponse> => {
  const apiResponse: AxiosResponse<ApiAppSettingsResponse> =
    await apiAuthClient.post(
      endpoints.chooseIntegrationSettings,
      integrationSettingsData,
      {
        headers: {
          ...getRandomExampleHeader([
            // 'response-1',
            // 'response-2',
            // 'response-3',
            // 'fb-response-2',
            // 'response-single-option',
            'add-option-api',
          ]),
        },
      }
    )

  const schemaSettings = apiResponse.data?.data?.schemaSettings || []
  return {
    data: {
      schemaSettings: schemaSettings,
    },
  }
}

export const useChooseIntegrationSettings = () =>
  useMutation<
    ApiAppSettingsResponse,
    ApiErrorResponse,
    {
      integrationSettingsData: ApiAppSettingsRequestBody
    },
    unknown
  >(postChooseIntegrationSettings)
