import React from 'react'
import * as Sentry from '@sentry/react'
// https://github.com/aws-amplify/amplify-js/blob/master/README.md
import { Amplify } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import awsmobile from './aws-exports'
import {
  AuthState,
  onAuthUIStateChange,
  CognitoUserInterface,
} from '@aws-amplify/ui-components'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Page404 from './pages/Page404'
import Saml from '@features/auth/slots/Saml'
import FbAppStore from './pages/AppStore/FbAppStore'
import Fbe from './pages/Fbe'
import FbeCallback from './pages/FbeCallback'
import LegacyRedirect from './pages/LegacyRedirect'
import Logout from './pages/Logout'
import Close from './pages/AppStore/Close'
import FbAppStoreTest from './pages/AppStore/FbAppStoreTest'
import { SignUp } from './pages/Signup'
import {
  setAuthStateAction,
  setCurrentUserAction,
  unsetAuthStateAction,
  unsetCurrentUserAction,
} from './store/actions/UserActions'
import SamlRedirect from './pages/SamlRedirect'
import { AuthRouter } from '@features/auth/AuthRouter'
import { appRoutes, bridgeByUrlPartnersPaths } from './routes'
import { useFirstLandRedirect } from '@features/auth/functions/useFirstLandRedirect'
import { parseAuthUser } from '@app/features/auth/functions/parseAuthUser'
import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { TestComponents } from '@app/pages/TestComponents'
import { GlobalAppStyle } from '@app/styles/GlobalAppStyle'
import { GlobalLoginStyle } from '@app/features/auth/components/AuthContainer.styled'
import { BridgeByUrlCallback } from '@app/pages/BridgeByUrlCallback'
import { PricingPage } from '@app/pages/Pricing'
import { CheckoutPage } from '@app/pages/Checkout/index'
import { SignUpBbu } from '@app/pages/SignupBbu'
import { DestinationContentSample } from '@components/ConnectedPopupWindow/DestinationContentSample'
import { ThankYouPage } from '@app/pages/ThankYouPage'
import { SuperUserInfoBar } from '@features/auth/SuperUserInfoBar'
import { identifyUser, useTrackPageView } from '@app/dataTracking'
import { SignUpAppSumo } from '@app/pages/SignupAppSumo'
import { SignupBbuFallbackSurvey } from '@app/pages/SignupBbuFallbackSurvey'
import { GoogleAdsAccountRouter } from '@app/pages/GoogleAdsAccount'
import { AdminPages } from '@app/pages/Admin'
import { IntegrationAuthVerifiedEmailScreenLegacy } from '@app/pages/Integration/IntegrationAuthVerifiedEmailScreenLegacy'
import { Loader } from '@components/Basic/Loader'
import { useGetUserInfo } from '@app/api/getUserInfo'
import SignIn from '@features/auth/slots/SignIn'
import { InfoPage } from '@features/nbee/StepCompleted/InfoPage'
import { Step1 } from '@app/pages/Nbee/Step1'
import { Step1Edit } from '@app/pages/Nbee/Step1Edit'
import { Step2 } from '@app/pages/Nbee/Step2'
import { Step3 } from '@app/pages/Nbee/Step3'
import { NbeeHome } from '@app/pages/Nbee/NbeeHome'
import { BridgeFormValues } from 'Nbee'
import { BridgeChooserForm } from '@features/nbee/BridgeChooserForm'
import { BridgeChooserRedirector } from '@features/nbee/BridgeChooserRedirector'
import { IncomingDataScreen } from '@app/pages/IncomingData/IncomingDataScreen'
import { IncomingDataScreenFields } from '@app/pages/IncomingData/IncomingDataScreenFields'
import { IncomingDataScreenSave } from '@app/pages/IncomingData/IncomingDataScreenSave'
import { IntegrationAuthCallback2 } from '@app/pages/Integration/IntegrationAuthCallback2'
import { IntegrationAuthCallback } from '@app/pages/Integration/IntegrationAuthCallback'
import { IntegrationAuthScreenEdit } from '@app/pages/Integration/IntegrationAuthScreenEdit'
import { IntegrationAuthScreen } from '@app/pages/Integration/IntegrationAuthScreen'
import ChangePassword from '@features/auth/slots/ChangePassword/ChangePassword'
import { useGetUserModules } from '@app/api/getUserModules'
import LostPassword from './features/auth/slots/LostPassword'

export const helpCenterUrl =
  'https://community.activeprospect.com/search?keywords=leadsbridge'

Amplify.configure(awsmobile)

const emptyBridge: BridgeFormValues = {
  name: `New bridge ${new Date().getTime()}`,
  source: {
    appId: undefined,
    integrationId: undefined,
    settings: [],
  },
  destination: {
    appId: undefined,
    integrationId: undefined,
    settings: [],
  },
  settings: {
    emailReceipt: {
      active: false,
    },
  },
}

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const authState = useAppSelector((state) => state.user.authState)
  const userAuthState = useAppSelector((state) => state.user.auth)
  const navigate = useNavigate()

  const { zE } = window

  const [shouldFetchUserData, setShouldFetchUserData] = React.useState(false)

  const { data: userData, isLoading: isLoadingUser } =
    useGetUserInfo(shouldFetchUserData)
  const { data: userModulesResponse } = useGetUserModules(shouldFetchUserData)

  const userModules = userModulesResponse?.modules || []
  const supportUserModule = userModules.find((m) => m.id === 'customer-support')
  const isDowngraded = userData?.user.downgraded

  const { initialSigninUrl, redirectsToWhenLogged } =
    useFirstLandRedirect(isDowngraded)

  // send page view to segment
  useTrackPageView(!!userAuthState?.username)

  React.useEffect(() => {
    if (isDowngraded) {
      navigate('/pricing')
    }
  }, [userData])

  React.useEffect(() => {
    if (zE) {
      zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            attachments: true,
            ticketForms: [{ id: supportUserModule?.userDefaults.formId }],
          },
        },
      })
      zE('webWidget', 'close')
      zE('webWidget', 'hide')
      if (supportUserModule) {
        zE('webWidget', 'prefill', {
          name: {
            value: supportUserModule?.userDefaults.fullName,
          },
          email: {
            value: supportUserModule?.userDefaults.email,
            readOnly: true,
          },
        })
      }
    }
  }, [supportUserModule])

  /**
   * Set user State in Redux State (SET_CURRENT_USER)
   */
  React.useEffect(() => {
    // Aws authenticator doesn't work as expected with React-Router,
    // see: https://github.com/aws-amplify/docs/issues/2895

    if (authState === undefined) {
      Auth.currentAuthenticatedUser()
        .then((authData) => {
          setShouldFetchUserData(true)
          const cognitoUser = parseAuthUser(authData as CognitoUserInterface)
          dispatch(setAuthStateAction(AuthState.SignedIn))
          dispatch(setCurrentUserAction(cognitoUser))
        })
        .catch((e) => {
          console.info('no auth state:', e)
          dispatch(unsetAuthStateAction())
          dispatch(unsetCurrentUserAction())
        })
    }

    return onAuthUIStateChange(
      (nextAuthState: AuthState, authData?: Record<string, any>) => {
        const cognitoUser = parseAuthUser(authData as CognitoUserInterface)
        dispatch(setAuthStateAction(nextAuthState))
        dispatch(setCurrentUserAction(cognitoUser))
      }
    )
  }, [])

  /**
   * Handle Tracking script (for visitor or Logged User)
   */
  React.useEffect(() => {
    if (userAuthState?.username) {
      identifyUser(userAuthState)
    }
  }, [userAuthState?.username])

  React.useEffect(() => {
    if (userData) {
      setShouldFetchUserData(false)
    }
  }, [userData])

  /**
   * Register user data to Sentry
   * https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
   */
  React.useEffect(() => {
    if (userAuthState && userAuthState.attributes) {
      Sentry.setUser({
        email: userAuthState.attributes.email,
        id: userAuthState.attributes['custom:legacyUserId'],
      })
    } else {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }, [userAuthState?.username])

  return (
    <div>
      <GlobalAppStyle />
      {(isLoadingUser && !userData) ||
      (authState === undefined && userAuthState === undefined) ? (
        <>
          {/* For a brief amount of seconds, in the beginning, both authState and userAuthState are undefined. */}
          {/* In these cases, we want to display a Loader. */}
          <Loader $active $dimmer $size={'large'} />
        </>
      ) : authState === AuthState.SignedIn && userAuthState ? (
        <>
          {/* LOGGED USER */}
          {/* If the user has logged in successfully, its authState will be equal to 'signin', */}
          {/* And its userAuthState will contain the user object obtained from Cognito. */}
          <SuperUserInfoBar />
          <Routes>
            <Route
              path={appRoutes.changePassword.makUrl()}
              element={<ChangePassword />}
            />
            {/* Routes per GoogleAdsAccountRouter */}
            <Route
              path={appRoutes.googleAdsAccountFormCreate.path}
              element={<GoogleAdsAccountRouter />}
            />
            <Route
              path={appRoutes.googleAdsAccountFormCreated.path}
              element={<GoogleAdsAccountRouter />}
            />
            <Route
              path={appRoutes.googleAdsAccountActivate.path}
              element={<GoogleAdsAccountRouter />}
            />
            <Route
              path={appRoutes.googleAdsAccountConnect.path}
              element={<GoogleAdsAccountRouter />}
            />

            {/* Routes per NbeeRouter */}

            <Route path={appRoutes.nbeeInfoPage.path} element={<InfoPage />} />

            <Route
              path={appRoutes.nbeeBridgeChooserStep1.path}
              element={
                <BridgeChooserForm
                  initialValues={emptyBridge}
                  onSubmit={(formValues) => {
                    // on submit we redirect to step 2
                    navigate(
                      appRoutes.nbeeBridgeChooserStep2.makeUrl({
                        sourceAppId: `${formValues.source.appId}`,
                        destinationAppId: `${formValues.destination.appId}`,
                      })
                    )
                  }}
                />
              }
            />

            {/*
            Step 2: Editor selection
            > NB: For some combinations, there is no 'complex bridge'. Within this Route, we check compatibility and manage the automatic redirect!
            */}
            <Route
              path={appRoutes.nbeeBridgeChooserStep2.path}
              element={<BridgeChooserRedirector />}
            />

            <Route path={appRoutes.nbeeStep1New.path} element={<Step1 />} />
            <Route path={appRoutes.nbeeAppBbu.path} element={<Step1 />} />
            <Route path={appRoutes.signupBbu.path} element={<Step1 />} />

            <Route
              path={appRoutes.nbeeStep1Edit.path}
              element={<Step1Edit />}
            />
            <Route path={appRoutes.nbeeStep2.path} element={<Step2 />} />
            <Route path={appRoutes.nbeeStep3.path} element={<Step3 />} />
            <Route path={appRoutes.nbee.path} element={<NbeeHome />} />

            <Route path={appRoutes.pricing.path} element={<PricingPage />} />
            <Route path={appRoutes.checkout.path} element={<CheckoutPage />} />
            <Route
              path={appRoutes.checkoutThankYouPage.path}
              element={<ThankYouPage />}
            />
            <Route path={appRoutes.fbAppStoreDone.path} element={<Close />} />
            <Route path={appRoutes.fbAppStore.path} element={<FbAppStore />} />
            <Route path='/fbAppStoreTest' element={<FbAppStoreTest />} />

            <Route
              path={appRoutes.fbeCallback.path}
              element={<FbeCallback />}
            />
            <Route path={appRoutes.fbe.path} element={<Fbe />} />

            <Route
              path={appRoutes.incomingDataScreen.path}
              element={<IncomingDataScreen />}
            />
            <Route
              path={appRoutes.incomingDataScreenEdit.path}
              element={<IncomingDataScreen />}
            />
            <Route
              path={appRoutes.incomingDataScreenFields.path}
              element={<IncomingDataScreenFields />}
            />
            <Route
              path={appRoutes.incomingDataScreenSave.path}
              element={<IncomingDataScreenSave />}
            />

            {/* TODO CHECK THESE ROUTES */}
            <Route
              path={appRoutes.incomingData.path}
              element={<IncomingDataScreen />}
            />
            {/*  <Route
              path={appRoutes.integration.path}
              element={<IncomingDataScreen />}
            /> */}

            <Route
              path={appRoutes.integrationAuthScreenCallback2.path}
              element={<IntegrationAuthCallback2 />}
            />
            <Route
              path={appRoutes.integrationAuthScreenCallback.path}
              element={<IntegrationAuthCallback />}
            />
            <Route
              path={appRoutes.integrationAuthScreenEdit.path}
              element={<IntegrationAuthScreenEdit />}
            />
            <Route
              path={appRoutes.integrationAuthScreenEditWithBridgeId.path}
              element={<IntegrationAuthScreenEdit />}
            />
            <Route
              path={appRoutes.integrationAuthScreenEditStep2.path}
              element={<IntegrationAuthScreenEdit />}
            />
            <Route
              path={appRoutes.integrationAuthScreen.path}
              element={<IntegrationAuthScreen />}
            />

            {/* TODO CHECK THIS ROUTES */}
            {/*  <Route element={<IntegrationHome />} /> */}

            <Route
              path={appRoutes.bridgeByUrlCallback.path}
              element={<BridgeByUrlCallback isMountedInLoggedRoute={true} />}
            />
            {/* here we handle the fast app survey, if needed */}
            {/* <BridgeByUrlCallback isMountedInLoggedRoute={true} /> */}

            <Route
              path={appRoutes.adminPrivateRoot.path}
              element={<AdminPages />}
            />

            <Route path={appRoutes.logout.path} element={<Logout />} />
            {/* users will always land on the app page as not logged,
                        so we need to "reverse" the redirect on the not logged <Switch>
                        to do this, we add a `?redirectTo=..` query string as url param
                        (logic inside useFirstLandRedirect hook)
                    */}
            <Route
              path={appRoutes.signin.path}
              element={<Navigate to={redirectsToWhenLogged} />}
            />
            <Route path='/test-app-route'>Welcome to Leadsbridge</Route>
            <Route path='/test-components' element={<TestComponents />} />
            {/* Route for Legacy Email Verification */}
            <Route
              path={'/verify-email'}
              element={<IntegrationAuthVerifiedEmailScreenLegacy />}
            />
            <Route
              path='/*'
              element={<LegacyRedirect isDowngraded={isDowngraded} />}
            />
            <Route element={<Page404 />} />
          </Routes>
        </>
      ) : (
        <>
          {/* NOT LOGGED USER */}
          {/* When the user is not logged, userAuthState will be undefined, while authState will still hold a value */}
          <GlobalLoginStyle />
          <Routes>
            <Route path='/test-components' element={<TestComponents />} />

            <Route
              path='/connected-popup-destination-url-example'
              element={<DestinationContentSample />}
            />
            <Route path={appRoutes.saml.path} element={<Saml />} />
            <Route
              path={appRoutes.samlRedirect.path}
              element={<SamlRedirect />}
            />
            <Route path={appRoutes.logout.path} element={<Logout />} />

            {/* Signup */}
            <Route path={appRoutes.signupBbu.path} element={<SignUpBbu />} />
            <Route
              path={appRoutes.signupPartner.path}
              element={<SignUpAppSumo />}
            />
            <Route path={appRoutes.signup.path} element={<SignUp />} />

            {/* Signup BBU Fallback survey is called when partner bbu fails to retrieve user email address from partner oauth. We signup the user asking his email address as survey. */}
            <Route
              path={appRoutes.signupBbuFallbackSurvey.path}
              element={<SignupBbuFallbackSurvey />}
            />

            {/* AmplifyAuthenticator in AuthRouter component */}

            <Route element={<AuthRouter />}>
              {/* exact matching paths here: */}
              {/* TODO CHECK FBE PATHS */}
              <Route path={appRoutes.fbe.path} element={<AuthRouter />} />
              <Route
                path={appRoutes.fbeCallback.path}
                element={<AuthRouter />}
              />
              <Route
                path={appRoutes.fbAppStoreDone.path}
                element={<AuthRouter />}
              />
              <Route
                path={appRoutes.forgotPassword.makeUrl()}
                element={<LostPassword />}
              />
              {/* partial matching paths here: */}
              <Route path={appRoutes.signin.path} element={<SignIn />} />
              <Route path={appRoutes.nbeeAppBbu.path} element={<SignIn />} />
              {bridgeByUrlPartnersPaths.map((path) => (
                <Route key={path} path={path} element={<SignIn />} />
              ))}
              <Route
                path={appRoutes.fbAppStore.path}
                element={<AuthRouter />}
              />
            </Route>

            <Route
              path={appRoutes.bridgeByUrlCallback.path}
              element={<BridgeByUrlCallback isMountedInLoggedRoute={false} />}
            />

            {/* Route for Legacy Email Verification */}
            <Route
              path={'/verify-email'}
              element={<IntegrationAuthVerifiedEmailScreenLegacy />}
            />

            {/* old saml url -> redirect to new one  */}
            <Route
              path='/saml'
              element={<Navigate to={appRoutes.saml.makeUrl()} />}
            />

            {/* When not logged, every other path will redirect to signin */}
            <Route path='/*' element={<Navigate to={initialSigninUrl} />} />
          </Routes>
        </>
      )}
    </div>
  )
}

export default App
