import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiBridgeResponse } from 'BackendApi'
import { transformBridgeApiToBridgeFormValues } from '@app/api/utils/bridge'
import { apiAuthClient } from '@app/services/apiAuthClient'

const fetchBridgeByTemplate = async (
  bbuUri: string
): Promise<ApiBridgeResponse> => {
  const apiResponse = await apiAuthClient.get(endpoints.getBridgeByTemplate, {
    params: {
      bbuUri: bbuUri,
    },
  })
  return apiResponse.data
}

export const useGetBridgeByTemplate = (bbuUri?: string) =>
  useQuery(['bridgeByTemplate', bbuUri], () => fetchBridgeByTemplate(bbuUri!), {
    select: transformBridgeApiToBridgeFormValues,
    enabled: !!bbuUri,
  })
