import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiGetIntegrationsResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { Integration } from 'Nbee'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const fetchIntegrationsByAppId = async (
  appId: number
): Promise<ApiGetIntegrationsResponse> => {
  const apiResponse = await apiAuthClient.get(
    endpoints.getIntegrationsByAppId,
    {
      params: {
        appId: appId,
      },
      headers: {
        ...getRandomExampleHeader([
          'with-oauth',
          // 'empty-list',
        ]),
      },
    }
  )
  return apiResponse.data
}

const adaptApiReponse = (
  apiReponse: ApiGetIntegrationsResponse
): Integration[] => {
  if (
    !apiReponse ||
    !apiReponse.data ||
    !apiReponse.data.integrations ||
    !apiReponse.data.integrations.length
  ) {
    return []
  }

  return apiReponse.data.integrations
}

export const useFetchIntegrationsByAppId = (appId: number) =>
  useQuery(['integrationByApp', appId], () => fetchIntegrationsByAppId(appId), {
    staleTime: 0,
    select: adaptApiReponse,
    enabled: !!appId,
  })
