import axios, { AxiosError } from 'axios'

const baseUrlV1 = `${process.env.REACT_APP_V1_API}`
const baseUrlLambda = `${process.env.REACT_APP_V2_API}`
const baseUrlV2 = `${process.env.REACT_APP_LARAVEL_API}`
const baseUrlS3 = `${process.env.REACT_APP_S3_API}`

const baseUrlV2UtilityEndpoint = (): string => {
  const apiV1String = `${process.env.REACT_APP_LARAVEL_API_V1}`
  const apiUtilsString = `${process.env.REACT_APP_LARAVEL_API_UTILITY}`
  if (baseUrlV2.includes(apiV1String) && baseUrlV2.includes('stoplight.io')) {
    // Development
    return baseUrlV2.replace(apiV1String, apiUtilsString)
  } else {
    return baseUrlV2 // Production
  }
}

/*
We will use this function when we remove the ID at the end the BaseUrlV2 endpoint path name

const baseUrlV2Formatted = (id: string) => {
  if (baseUrlV2.includes('stoplight.io')) {
    return `${baseUrlV2}/${id}`
  } else {
    return baseUrlV2
  }
}
*/

export const endpoints = {
  handleOauthNotLoggedUser: `${baseUrlV1}/auth/v2/handleOauthUser`,
  handleOauthLoggedUser: `${baseUrlV1}/auth/v2/handleOauth`,
  integrationsMeta: `${baseUrlLambda}/integrations/getMeta`,
  fastAppSurveyData: `${baseUrlS3}/public/classification_data.json`,
  storeAppSurvey: `${baseUrlV1}/auth/v2/storeAppSurvey`,
  setupBridge: `${baseUrlV1}/fbe/v2/setupBridge`,
  getCurrencies: `${baseUrlV2UtilityEndpoint()}/utilities/currencies`,
  getTimezones: `${baseUrlV2UtilityEndpoint()}/utilities/timezones`,
  getUserGeolocation: `${baseUrlV2UtilityEndpoint()}/utilities/userGeolocation`,
  // nbee
  getApps: `${baseUrlV2}/app`,
  getAppById: ({ appId }: { appId: string | number }) =>
    `${baseUrlV2}/app/${appId}`,
  getConnectedApps: `${baseUrlV2}/app/myApps`,
  getMostPopularApps: `${baseUrlV2}/app/mostPopular`,
  getPopularConnections: `${baseUrlV2}/app/mostPopularConnections`,
  getVerifiedEmails: `${baseUrlV2}/verifiedContact/myContacts`,
  getFiltersSchema: `${baseUrlV2}/bridge/filterSchema`,
  getFormulasSchema: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/formulaSchema`,
  getSingleFormulaSchema: ({
    bridgeId,
    formulaId,
  }: {
    bridgeId: string
    formulaId: string
  }) => `${baseUrlV2}/bridge/${bridgeId}/formulaSchema/${formulaId}`,
  postVerifyCode: `${baseUrlV2}/verifiedContact/verifyCode`,
  postVerifyLink: `${baseUrlV2}/verifiedContact/verifyLink`,
  postSendCode: `${baseUrlV2}/verifiedContact/sendCode`,
  checkAppsCompatibility: `${baseUrlV2}/app/checkCompatibility`,
  getAppAuthSchemaByAppId: ({ appId }: { appId: string | number }) =>
    `${baseUrlV2}/app/${appId}/credentials`,
  getIntegrationsByAppId: `${baseUrlV2}/integration/myIntegrations`,
  getIntegrationById: ({ integrationId }: { integrationId: string | number }) =>
    `${baseUrlV2}/integration/${integrationId}`,
  getIntegrationByLegacyId: `${baseUrlV2}/integration/byLegacyId`,
  getIntegrationUsage: ({
    integrationId,
  }: {
    integrationId: string | number
  }) => `${baseUrlV2}/integration/${integrationId}/usage`,
  saveIntegration: `${baseUrlV2}/integration`,

  // Incoming webhook
  getIntegrationListener: ({
    integrationId,
  }: {
    integrationId: string | number
  }) => `${baseUrlV2}/integration/${integrationId}/listener`,
  updateIntegrationListener: ({
    integrationId,
  }: {
    integrationId: string | number
  }) => `${baseUrlV2}/integration/${integrationId}/listener`,

  // New integration oauth
  postAuthGrantCode: `${baseUrlV2}/integration/authenticate`,
  postGenRedirectUri: `${baseUrlV2}/app/generateRedirectUri`,

  updateIntegrationById: ({
    integrationId,
  }: {
    integrationId: string | number
  }) => `${baseUrlV2}/integration/${integrationId}`,
  testIntegrationCredentialsById: ({
    integrationId,
  }: {
    integrationId: string
  }) => `${baseUrlV2}/integration/${integrationId}/test`,
  getBridgeById: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}`,
  getRedirectUriBySourceAndDestination: `${baseUrlV2}/app/bridgeTableUri`,
  getBridgeByTemplate: `${baseUrlV2}/bridge/byTemplate`,
  createNewBridge: `${baseUrlV2}/bridge`,
  createUpdateBridgeFilterRules: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/filter`,
  deleteBridgeFilterRules: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/filter`,
  setupBridgeById: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/setup`,
  updateBridgeById: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}`,
  publishBridgeById: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/publish`,
  disableBridgeById: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/draft`,
  chooseIntegrationSettings: `${baseUrlV2}/nbee/chooseIntegrationSettings`,
  addDropdownOption: `${baseUrlV2}/nbee/addDropdownOption`,
  getBridgeFields: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/fields`,
  getTestableBridgeFields: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/testFields`,
  testFieldsMapping: ({ integrationId }: { integrationId: string | number }) =>
    `${baseUrlV2}/nbee/testFieldsMapping/${integrationId}`,
  sendTestLead: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/test`,
  getRunBridgePermissionCheck: ({ bridgeId }: { bridgeId: string }) =>
    `${baseUrlV2}/bridge/${bridgeId}/permissionCheck`,
  // auth
  authWithExternalProvider: `${baseUrlV2}/user/externalProviderAuth`,
  signup: `${baseUrlV2}/user/signUp`,
  // billing
  createPaymentIntent: `${baseUrlV1}/checkout/v2/create_payment_intent`,
  storeCreditCard: `${baseUrlV2}/billing/storeCreditCard`,
  changePlan: `${baseUrlV2}/billing/changePlan`,
  planAvailable: `${baseUrlV2}/billing/planAvailable`,
  getPlans: `${baseUrlV2}/billing/plans`,
  getCheckoutInfo: ({ planId }: { planId: string }) =>
    `${baseUrlV2}/billing/checkoutInfo/${planId}`,
  getCountries: `${baseUrlV2UtilityEndpoint()}/utilities/countries`,
  getStates: ({ countryCode }: { countryCode: string }) =>
    `${baseUrlV2UtilityEndpoint()}/utilities/countries/${countryCode}/states`,

  // user
  getUser: `${baseUrlV2}/user/me`,
  billingInfo: `${baseUrlV2}/user/billingInfo`,
  getUserModules: `${baseUrlV2}/user/modules`,
  postCreateGoogleAdsAccount: `${baseUrlV2}/google/customerAccount`,
  getCustomerGoogleAdsAccountBillingInfo: ({
    customerId,
  }: {
    customerId: string
  }) => `${baseUrlV2}/google/customerAccount/${customerId}/status`,
  patchResetDowngradeFreemium: `${baseUrlV2}/user/resetDowngradeFreemium`,
}

export const getApiErrorMessage = ({
  error,
  fallbackMessage = 'Generic Error',
}: {
  error: Error | AxiosError
  fallbackMessage?: string
}) => {
  if (axios.isAxiosError(error)) {
    return error.response?.data?.errorMessage || fallbackMessage
  }

  if (typeof error === 'string') {
    return error || fallbackMessage
  }

  return fallbackMessage
}
